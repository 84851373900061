
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.Part4 {
    :global {
        position: relative;
        padding-top: 160px;
        width: 100%;
        background-color: #fff;
        text-align: center;
        .title {
            margin: auto;
            width: 718px;
            font-family: var(--ff-woff2-EB);
            font-size: 32px;
            line-height: 1.2;
        }
        .desc {
            margin: 12px auto 0;
            width: 718px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.4;
            color: #707070;
            html[site='de'] & {
                width: 750px;
            }
        }
        .video {
            position: relative;
            margin-top: 30px;
            width: 100%;
            .ef-video {
                margin: auto;
                max-width: 1100px;
                width: 100%;
                height: auto;
            }
        }
        .video-h5 {
            display: none;
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            padding-top: 120px;
            text-align: left;
            .title {
                width: 100% !important;
                padding: 0 48px;
                font-size: 24px;
            }
            .desc {
                width: 100% !important;
                padding: 0 48px;
                font-size: 14px;
            }
            .video {
                margin-top: 24px;
                padding: 0 48px;
            }
        }
        @include devices(h5) {
            padding-top: 120px;
            text-align: left;
            .title {
                padding: 0 24px !important;
            }
            .desc {
                padding: 0 24px !important;
            }
            .video {
                display: none;
            }
            .video-h5 {
                display: block;
                position: relative;
                margin: 24px 24px 0;
                .ef-video {
                    width: 100%;
                    height: auto;
                    overflow: hidden;
                    border-radius: 12px;
                }
            }
        }
    }
}

.Part5 {
    :global {
        padding-top: 160px;
        width: 100%;
        background-color: #fff;
        overflow: hidden;
        .text-wrapper {
            margin: auto;
            width: 880px;
            text-align: center;
        }
        .title {
            font-family: var(--ff-woff2-EB);
            font-size: 32px;
            line-height: 1.2;
        }
        .desc {
            margin-top: 12px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.4;
            color: #707070;
        }
        .video-pc {
            display: block;
            margin: 30px auto 0;
            max-width: 1100px;
            height: auto;
            border-radius: 20px;
        }
        .video-h5 {
            display: none;
            margin-top: 30px;
            margin-bottom: -1px;
            width: 100%;
            height: auto;
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            padding-top: 120px;
            .text-wrapper {
                margin: auto;
                width: 100% !important;
                text-align: left;
                .title {
                    width: 100% !important;
                    padding: 0 48px;
                    font-size: 24px;
                }
                .desc {
                    width: 100% !important;
                    padding: 0 48px;
                    font-size: 14px;
                }
            }
            .ef-video {
                margin-top: 24px;
            }
            .video-pc {
                display: none;
            }
            .video-h5 {
                display: block;
            }
        }
        @include devices(h5) {
            padding-top: 120px;
            text-align: left;
            .text-wrapper {
                margin: auto;
                width: 100%;
                .title {
                    padding: 0 24px !important;
                }
                .desc {
                    padding: 0 24px !important;
                }
            }
        }
    }
}

.Part6 {
    :global {
        padding-top: 160px;
        width: 100%;
        background-color: #fff;
        .text-wrapper {
            margin: auto;
            width: 700px;
            text-align: center;
        }
        .title {
            font-family: var(--ff-woff2-EB);
            font-size: 32px;
            line-height: 1.2;
        }
        .desc {
            margin-top: 12px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.4;
            color: #707070;
        }
        .video {
            position: relative;
            margin-top: 30px;
            width: 100%;
            .ef-video {
                display: none;
                margin: auto;
                max-width: 1100px;
                width: 100%;
                height: auto;
                &.show {
                    display: block;
                }
            }
        }
        .video-h5 {
            display: none;
            .ef-video {
                display: none;
                &.show {
                    display: block;
                }
            }
        }
        .switch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px auto 0;
            width: max-content;
            padding: 7px;
            border-radius: 90px;
            background: #f4f4f4;
            .active {
                background: #373c46;
                .text {
                    color: #fff;
                }
            }
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 60px;
                border-radius: 90px;
                cursor: pointer;
            }
            .text {
                display: block;
                margin-top: 4px;
                font-family: var(--ff-woff2-M);
                font-size: 12px;
            }
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            padding-top: 120px;
            .text-wrapper {
                margin: auto;
                width: 100%;
                text-align: left;
                .title {
                    width: 100% !important;
                    padding: 0 48px;
                    font-size: 24px;
                }
                .desc {
                    width: 100% !important;
                    padding: 0 48px;
                    font-size: 14px;
                }
            }
            .video {
                margin-top: 24px;
                padding: 0 48px;
            }
            .switch {
                padding: 5px;
                border-radius: 80px;
                div {
                    width: 90px;
                    height: 50px;
                    border-radius: 80px;
                }
                .text {
                    font-size: 10px;
                }
            }
        }
        @include devices(h5) {
            padding-top: 120px;
            text-align: left;
            .text-wrapper {
                margin: auto;
                width: 100%;
                .title {
                    padding: 0 24px !important;
                }
                .desc {
                    height: 60px;
                    padding: 0 24px !important;
                }
            }
            .video {
                display: none;
            }
            .video-h5 {
                display: block;
                position: relative;
                margin: 24px 24px 0;
                border-radius: 12px;
                overflow: hidden;
                .ef-video {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.Part7 {
    :global {
        padding-top: 160px;
        width: 100%;
        background-color: #fff;
        text-align: center;
        overflow: hidden;
        .title {
            margin: auto;
            width: 718px;
            font-family: var(--ff-woff2-EB);
            font-size: 32px;
            line-height: 1.2;
        }
        .desc {
            margin: 12px auto 0;
            width: 718px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.4;
            color: #707070;
            html[site='fr'] & {
                width: 800px;
            }
        }
        .img {
            position: relative;
            margin-top: 40px;
            padding: 85px 128px;
            width: 100%;
            background-color: #f4f4f4;
            .ef-picture {
                margin: auto;
                max-width: 1200px;
                height: auto;
            }
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            padding-top: 120px;
            text-align: left;
            .title {
                width: 100% !important;
                padding: 0 48px;
                font-size: 24px;
            }
            .desc {
                width: 100% !important;
                padding: 0 48px;
                font-size: 14px;
            }
            .img {
                margin-top: 24px;
                padding: 40px 110px;
            }
        }
        @include devices(h5) {
            padding-top: 120px;
            text-align: left;
            .title {
                padding: 0 24px;
            }
            .desc {
                padding: 0 24px;
            }
            .img {
                margin-top: 24px;
                padding: 40px 0;
                .ef-picture {
                    margin-left: -1px;
                    width: 101%;
                }
            }
        }
    }
}

.Part8 {
    :global {
        padding: 160px 0 200px;
        width: 100%;
        background-color: #fff;
        text-align: center;
        .title {
            margin: auto;
            width: 718px;
            font-family: var(--ff-woff2-EB);
            font-size: 32px;
            line-height: 1.2;
            html[site='fr'] & {
                width: 850px;
            }
        }
        .desc {
            margin: 12px auto 0;
            width: 718px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.4;
            color: #707070;
        }
        .img {
            position: relative;
            margin-top: 40px;
            padding: 0 128px;
            width: 100%;
            .ef-picture {
                margin: auto;
                max-width: 1200px;
                height: auto;
            }
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            padding: 120px 0 120px;
            text-align: left;
            .title {
                width: 100% !important;
                padding: 0 48px;
                font-size: 24px;
            }
            .desc {
                width: 100% !important;
                padding: 0 48px;
                font-size: 14px;
            }
            .img {
                margin-top: 24px;
                padding: 0 48px;
                .ef-picture {
                    width: 100%;
                }
            }
        }
        @include devices(h5) {
            padding: 120px 0 120px;
            text-align: left;
            .title {
                padding: 0 24px;
            }
            .desc {
                padding: 0 24px;
            }
            .img {
                margin-top: 24px;
                padding: 0 24px;
                .ef-picture {
                    width: 100%;
                }
            }
        }
    }
}
