
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.firstScreenH5 {
    height: 200vh;
    position: relative;
    :global {
        .video_box {
            position: sticky;
            top: 0;
            width: 100%;
            height: 100vh;
            margin-top: -0.95rem;
            overflow: hidden;
            .video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .video_box_mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1;
            background: rgba(0, 0, 0, 0.8);
        }

        .video_box_context {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 1;

            .title {
                font-size: 0.36rem;
                font-family: var(--ff-woff2-EB);
                color: #fff;
                line-height: 0.44rem;
                html[site='cn'] & {
                    font-weight: 700;
                    width: 3.55rem;
                    font-size: 0.34rem;
                    line-height: 0.42rem;
                }
                html[site='jp'] & {
                    width: 3.55rem;
                    font-weight: 500;
                }
                html[site=br] & {
                    font-size: .32rem;
                }
            }
            .desc {
                margin-top: 0.12rem;
                font-size: 0.14rem;
                font-family: var(--ff-woff2-EB);
                color: #999;
                line-height: 0.22rem;
                html[site='cn'] & {
                    font-weight: 600;
                    width: 2.8rem;
                }
                html[site='jp'] & {
                    width: 2.8rem;
                    color: #fff;
                    font-weight: 400;
                }
                html[site=br] & {
                    padding: 0 .24rem;
                }
            }
            .link {
                margin-top: 0.12rem;
                html[site='jp'] & {
                    width: 2.8rem;
                }
                a {
                    font-size: 0.17rem;
                    font-family: var(--ff-woff2-R);
                    color: #2164ff;
                    line-height: 0.25rem;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
            .link2 {
                html[site='jp'] & {
                    width: 1.9rem;
                }
            }
            .play-item {
                margin-top: 0.3rem;
                width: 2.1rem;
                height: 0.6rem;
                position: relative;
                .hide-cn {
                    html[site='cn'] & {
                        display: none;
                    }
                }
            }
        }
    }
}
