
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.quickGuide {
    :global {
        .quick-guide {
            width: 1120px;
            margin: auto;
            display: flex;
            .content-box {
                border-left: 1px solid #ededed;
                .title {
                    width: 686px;
                    font-size: 32px;
                    font-family: var(--font-family-SemiBold);
                    color: #03060b;
                    line-height: 35px;
                    margin-left: 56px;
                    margin-top: 60px;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 500;
                    }
                }
                .child-box {
                    margin-top: 30px;
                }
            }
            .ef-download-menu-custom.z1 {
                background-color: #f8f8f8 !important;
                margin-top: 60px;
                .ant-menu-sub {
                    background-color: #f8f8f8 !important;
                }
                .ant-menu-item-selected {
                    background-color: #efefef !important;
                }
                .ant-menu-title-content {
                    white-space: pre-line;
                    line-height: 25px;
                }
            }
        }
    }
}
