
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.naver {
    height: auto;
    background-color: transparent;
    // transition: all 200ms ease-in-out;

    // background-color: #ededed;
    &:global(.frost) {
        transform: translate3d(0px, 0.0001px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        // background-color: rgba(255, 255, 255, 0.72);
        background-color: rgba(68, 68, 68, 0.98);
        margin-bottom: 1px;
        // => start: 2022-07-11 UI要求改产品导航的下划线为全屏长度
        // border-bottom: 1px solid rgba(235, 233, 233, .2);
        // backdrop-filter: blur(20px);

        :global {
            .container {
                .detail-nav {
                    border-bottom: 0;

                    // => 2022-07-18：详情页nav UI要求改为一种风格（黑底白字），FAQ和Spec则保持原有两种主题
                    .left,
                    .rightLists > li {
                        color: #fff !important;
                    }

                    .rightLists > li.active::after {
                        background-color: #fff !important;
                    }

                    .right {
                        .tab-dropdown-name {
                            color: #fff;
                        }

                        .tab-dropdown-icon {
                            transform: rotate(0deg);
                            background: url('https://websiteoss.ecoflow.com/icons/5ef9162d51250932fa3ef3984ac24641.svg') center center/100% no-repeat;
                        }
                    }
                }
                .black {
                    border-bottom: 0 !important;
                }
            }
        }

        // &:global(.black) {
        //     background-color: rgba(29, 29, 31, 0.72);
        //     border-bottom: 1px solid rgba(235, 233, 233, .2);
        // }

        // end
    }

    :global {
        .detail-nav {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: bold;
            color: var(--navbg);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            height: 64px;

            &.black {
                border-bottom: 1px solid rgba(235, 233, 233, 0.2);
            }

            .left {
                line-height: 64px;
                height: 64px;
                font-size: 20px;
                font-family: var(--font-family-Medium);
                font-weight: 500;
            }

            .right {
                display: flex;
                align-items: center;

                .rightLists {
                    height: 64px;
                    margin-right: 20px;

                    li {
                        display: inline-block;
                        line-height: 64px;
                        margin-left: 36px;
                        font-size: 14px;
                        cursor: pointer;
                        box-sizing: border-box;
                        font-family: var(--font-family-Regular);

                        html[site='jp'] & {
                            font-family: var(--font-family-Regular);
                        }
                        html[site='cn'] & {
                            font-weight: 400;
                        }

                        &:hover {
                            // color: var(--navbg);
                            opacity: 0.7;
                        }
                    }

                    .active {
                        position: relative;
                        opacity: 0.6;

                        &:hover {
                            opacity: 0.7;
                        }

                        // &::after {
                        //     position: absolute;
                        //     bottom: -1px;
                        //     left: 0;
                        //     width: 100%;
                        //     height: 1px;
                        //     display: block;
                        //     content: '';
                        //     background-color: #03060b;
                        // }

                        // &.black::after {
                        //     background-color: #fff;
                        // }
                    }
                }

                .buy-btn {
                    height: 30px;
                    line-height: normal;
                    border-radius: 4px;
                    background: #2668ff;
                    font-family: var(--font-family-Regular);
                }
            }

            .right > a {
                margin-left: 36px;
            }
        }
    }

    &:global(.black) {
        &:global(.black) {
            :global {
                .tab-dropdown-name {
                    color: #fff;
                }
            }
        }

        :global {
            .detail-nav {
                // border-bottom: 1px solid rgba(235, 233, 233, .2);

                .left {
                    color: #fff !important;
                }

                .right {
                    .rightLists {
                        li {
                            font-family: var(--font-family-Regular);

                            &:hover {
                                color: #fff;
                                opacity: 0.7;
                            }
                        }

                        .active {
                            opacity: 0.6;
                            // color: var(--navbg);
                            // border-bottom: 1px solid var(--navbg);
                        }

                        // .active.black {
                        //     color: #fff;
                        //     border-bottom: 1px solid #fff;
                        // }
                    }
                }
            }
        }
    }

    @include maxWidth('lg') {
        &:global(.black) {
            :global {
                .right {
                    .vertical-nav {
                        background-color: rgb(34, 34, 34);
                        // box-shadow: 0 0.04rem 0.1rem 0.02rem rgba(255, 255, 255, 0.08);
                    }
                }

                .detail-nav .right li {
                    color: #919191;
                }

                .tab-dropdown {
                    .tab-dropdown-icon {
                        background: url('https://websiteoss.ecoflow.com/icons/5ef9162d51250932fa3ef3984ac24641.svg') center center/100% no-repeat;
                        transform: rotate(0deg);
                    }
                }
            }
        }

        :global {
            .btn-link {
                .buy-btn {
                    min-width: 0.44rem !important;
                    height: 0.28rem !important;
                    line-height: 0.24rem;
                    border-radius: 0.04rem;
                    padding: 0.03rem 0.11rem 0.04rem;
                }
            }

            .detail-nav {
                padding: 0 0.14rem;
                align-items: center;
                height: 0.44rem;

                .left {
                    max-width: 2rem;
                }
            }

            .detail-nav .right .rightLists li {
                font-family: var(--font-family-Medium);
                font-weight: 400;
                font-size: 0.12rem;
                color: rgb(102, 102, 102);
                line-height: 0.44rem;

                &.active {
                    color: #03060b;
                }
            }

            .detail-nav .right a {
                margin-left: 0;
            }

            .detail-nav .right .active {
                border-bottom: none !important;
            }

            .detail-nav .left {
                margin-right: 5px;
                font-size: 0.16rem;
                font-family: var(--font-family-Bold);
                line-height: 0.15rem;
                height: auto;
                font-weight: 700;
                html[site='fr'] & {
                    font-size: 0.14rem;
                }

                html[site='de'] & {
                    font-size: 0.14rem;
                }
            }

            .detail-nav .right .rightLists li {
                margin-left: 0.1rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            .detail-nav .right .btn-link {
                margin-left: 0.2rem;
                html[site='fr'] & {
                    margin-left: 0.1rem;
                }
            }

            .detail-nav .right .rightLists {
                height: 0.44rem;

                li {
                    font-size: 0.14rem;
                }
            }

            .efButton {
                min-width: 0.44rem !important;
                height: 0.24rem !important;
                font-size: 0.12rem !important;
                padding: 0.02rem 0.11rem 0.05rem;
            }

            .tab-dropdown {
                display: flex;
                align-items: center;

                .tab-dropdown-name {
                    font-family: Manrope-Light, Ef-Font-Light, "Arial", sans-serif;
                    font-weight: 300;
                    font-size: 0.14rem;

                    html[site='fr'] & {
                        font-size: 0.12rem;
                    }
                    html[site='de'] & {
                        font-size: 0.12rem;
                    }
                    html[site='jp'] & {
                        white-space: pre-line;
                    }
                }

                .tab-dropdown-icon {
                    width: 0.18rem;
                    height: 0.18rem;
                    margin: 0.01rem 0 0 0.06rem;
                    background: url('https://websiteoss.ecoflow.com/icons/87988d1cadf213803af8c097c3b3e632.svg') center center/100% no-repeat;
                    transform: rotate(90deg);
                }
            }

            .right {
                position: relative;

                .vertical-nav {
                    position: absolute;
                    width: 1.44rem;
                    right: 0.42rem;
                    top: 0.345rem;
                    padding: 0.2rem 0;
                    background-color: #fff;
                    transition: all 0.3s ease;
                    border-radius: 0.09rem;
                    box-shadow: 0 0.04rem 0.1rem 0.02rem rgba(0, 0, 0, 0.08);

                    ul li {
                        width: 100%;
                        font-family: Manrope-Light, Ef-Font-Light, "Arial", sans-serif;
                        font-weight: 300;
                        font-size: 0.14rem;
                        color: rgb(102, 102, 102);
                        margin-bottom: 0.25rem;
                        text-align: center;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &:hover,
                        &:focus {
                            color: rgb(38, 115, 255);
                        }

                        &.active {
                            color: rgb(38, 115, 255);
                        }
                    }

                    // background-color: blue;
                }
            }
        }
    }
}

@supports (-webkit-backdrop-filter: initial) or (backdrop-filter: initial) {
    .naver {
        &:global(.frost) {
            background-color: rgba(68, 68, 68, 0.98);
            // background-color: rgba(255, 255, 255, 0.72);
            // backdrop-filter: blur(20px);
        }
    }
}
