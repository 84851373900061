
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.bluetooth {
    :global {
        .bluetooth {
            width: 3.25rem;
            margin: auto;
            .main-title {
                font-size: 0.2rem;
                font-family: var(--font-family-SemiBold);
                color: #03060b;
                line-height: 0.28rem;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 500;
                }
            }
            .bluetooth-title {
                margin-top: 0.12rem;
                font-size: 0.16rem;
                font-family: var(--font-family-SemiBold);
                color: #03060b;
                line-height: 0.24rem;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 500;
                }
            }
            .common-desc {
                font-size: 0.14rem;
                font-family: var(--font-family-Regular);
                color: #35404e;
                line-height: 0.22rem;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 400;
                }
            }
            .desc1 {
                margin-top: 0.12rem;
            }
            .desc2 {
                margin-top: 0.22rem;
            }
            .step-box {
                .step-item {
                    padding-bottom: 0.17rem;
                    margin-top: 0.3rem;
                    border-bottom: 1px solid #ededed;
                    .step-img {
                        width: 2.54rem;
                        height: 4.9rem;
                        position: relative;
                    }
                    .step-desc {
                        margin-top: 0.16rem;
                        font-size: 0.14rem;
                        color: #35404e;
                        line-height: 0.22rem;
                        font-family: var(--font-family-Regular);
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                    }
                }
            }
            .note-box {
                margin-top: 0.6rem;
                .note {
                    font-size: 0.14rem;
                    font-family: var(--font-family-SemiBold);
                    color: #35404e;
                    line-height: 0.22rem;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 500;
                    }
                }
                .note-desc {
                    white-space: pre-line;
                    color: #757575;
                    font-size: 0.12rem;
                    line-height: 0.2rem;
                    font-family: var(--font-family-Regular);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
