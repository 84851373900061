
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.supportLeftMenu {
    width: 294px;
    flex-shrink: 0;
    margin-right: 40px;
    padding-bottom: 174px;
    :global {
        .searchIcon {
            width: 25px;
            height: 25px;
        }
        .search-input {
            margin: 72px 0 32px;
            width: 294px;
            height: 48px;
            border-radius: 8px;
            background-color: #f8f8f8;
            .ant-input-prefix {
                margin-right: 14px;
            }
            &:hover {
                background-color: #f6f6f6;
                .ant-input {
                    background-color: #f6f6f6;
                }
            }
            border: none !important;
            box-shadow: none !important;
            /*重置input --start--*/
            .ant-input {
                width: 100%;
                height: 100%;
                background-color: #f8f8f8;
            }
            /*重置input --end--*/
        }

        /*重置antd menu样式 --start--*/
        .ef-download-menu-custom.z1 {
            width: 294px;
            border: none;
            font-size: 17px;
            font-weight: 600;
            line-height: 1.47;
            color: var(--navbg);
            background-color: #fff;
            .ant-menu-submenu {
                .ant-menu-submenu-title {
                    align-items: center;
                    padding-right: 0;
                    .ant-menu-title-content {
                        line-height: normal;
                    }
                    .icon {
                        transition: all 0.3s;
                        line-height: 0;
                        transform: rotate(-90deg);
                        &.open {
                            transform: rotate(0deg);
                        }

                        html[site='sa'] & {
                            transform: rotate(90deg);
                            &.open {
                                transform: rotate(0deg);
                            }
                        }
                    }
                    &:hover {
                        color: var(--global);
                    }
                }
                .ant-menu-sub {
                    .ant-menu-submenu {
                        .ant-menu-submenu-title {
                            &:hover {
                                color: var(--global);
                            }
                        }
                    }
                }
            }
            .ant-menu-item,
            .ant-menu-submenu-title {
                height: 70px;
                margin: 4px 0 0 0;
                color: var(--navbg);

                html[site='sa'] & {
                    padding-left: 0 !important;
                }
            }
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu .ant-menu-submenu-title {
                // 一级菜单样式
                font-family: var(--font-family-Medium);
                font-weight: 500;
                font-size: 17px;

                // 二级菜单样式
                .ant-menu-item,
                .ant-menu-submenu,
                .ant-menu-submenu .ant-menu-submenu-title {
                    &:not(.ant-menu-submenu) {
                        height: 56px;
                    }
                    &.ant-menu-submenu-open {
                        .ant-menu-submenu-title {
                            position: relative;
                            &:before {
                                position: absolute;
                                content: '';
                                display: block;
                                height: 1px;
                                width: 100%;
                                background-color: #e5e5e5;
                                bottom: 0;
                            }
                        }
                    }
                    font-family: var(--font-family-Medium);
                    font-weight: 500;
                    font-size: 14px;
                    // 三级菜单样式
                    .ant-menu-item,
                    .ant-menu-submenu,
                    .ant-menu-submenu .ant-menu-submenu-title {
                        &:not(.ant-menu-submenu) {
                            height: 56px;
                        }
                        font-family: var(--font-family-Regular);
                        font-weight: 400;
                        color: #666;

                        html[site='sa'] & {
                            padding-right: 45px;
                        }
                    }
                }

                .ant-menu-submenu .ant-menu-submenu-title {
                    html[site='sa'] & {
                        padding-right: 30px;
                    }
                }

                &::after {
                    right: auto;
                    left: 0;
                    color: #2673ff;
                    width: 4px;

                    html[site='sa'] & {
                        right: 0;
                        left: auto;
                    }
                }
                &.ant-menu-item-selected {
                    background-color: #f8f8f8;
                    color: #2673ff;
                }

                .ant-menu-sub {
                    background-color: #fff;
                }
            }
            .ant-menu-submenu-selected {
                color: var(--navbg);
            }
            .ant-menu-item-selected {
                color: #2673ff !important;
            }
            .ant-menu-submenu-title:hover {
                color: var(--navbg);
            }
            .ant-menu-item-only-child:hover {
                color: #2673ff !important;
            }
        }
    }
    @include maxWidth('lg') {
        :global {
        }
    }
    @include maxWidth('md') {
        :global {
        }
    }
    @include maxWidth('xs') {
        :global {
            width: 120px;
            margin-right: 0;
            .search-input {
                width: 120px;
            }
            .ef-download-menu-custom.z1 {
                width: 120px;
            }
            .ant-menu-inline.ant-menu-root .ant-menu-item {
                height: auto;
            }
            .ef-download-menu-custom.z1 .ant-menu-submenu-title {
                height: auto;
            }
            .ef-download-menu-custom.z1 .ant-menu-title-content {
                white-space: normal;
                font-size: 12px;
                line-height: 16px;
                padding: 10px 0;
            }
            .ef-download-menu-custom.z1 .ant-menu-submenu .ant-menu-submenu .ant-menu-item {
                padding-left: 24px !important;
                line-height: 20px;
            }
        }
    }
}
