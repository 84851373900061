
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.firstSeriesInApp {
    position: relative;
    background: #fff;
    :global {
        .container {
            html[site='jp'] & {
                width: 1180px !important;
            }
        }
        .series_scoll_top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 800px;
            background: #f8f8f8;
            // transform: translateY(200px);
        }
        .app_series_scoll {
            float: left;
            width: 490px;
            position: relative;
            z-index: 1;
            html[site='jp'] & {
                width: 550px;
            }

            .app_series_scoll_item {
                height: 100vh;
                display: flex;
                flex-direction: column;
                &.first_scoll {
                    padding-top: 304px;
                }
                .text {
                    font-size: 56px;
                    font-family: var(--font-family-ExtraBold);
                    color: #03060b;
                    line-height: 64px;
                    html[site='jp'] & {
                        font-weight: 500;
                        font-size: 54px;
                        font-family: var(--font-family-SemiBold);
                        white-space: pre-line;
                    }
                    html[site='cn'] & {
                        font-weight: 700;
                        white-space: pre-line;
                    }
                    // opacity: 0;
                }

                .title {
                    font-size: 56px;
                    font-family: var(--font-family-ExtraBold);
                    color: #03060b;
                    line-height: 64px;
                    html[site='jp'] & {
                        font-weight: 500;
                        font-size: 54px;
                        font-family: var(--font-family-SemiBold);
                        white-space: pre-line;
                    }
                    html[site='cn'] & {
                        font-weight: 700;
                        white-space: pre-line;
                    }
                }
                .scoll01 {
                    margin-top: 150px;
                }

                .desc {
                    margin-top: 20px;
                    font-size: 22px;
                    font-family: var(--font-family-Bold);
                    color: #666;
                    line-height: 36px;
                    html[site='jp'] & {
                        font-family: var(--font-family-SemiBold);
                        font-size: 18px;
                        line-height: 25px;
                        font-weight: 400;
                    }
                    html[site='cn'] & {
                        font-weight: 600;
                        white-space: pre-line;
                    }
                    html[site='cis'] & {
                        line-height: 28px;
                    }
                }

                .icon_list {
                    margin-top: 60px;
                    color: #03060b;
                    display: flex;
                    justify-content: space-between;

                    .icon_list_item {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        justify-items: center;
                        align-items: center;

                        font-size: 16px;
                        font-family: var(--font-family-Bold);
                        color: #03060b;
                        line-height: 20px;
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 600;
                        }
                        html[site='de'] & {
                            width: 80px;
                            text-align: center;
                        }
                        html[site='fr'] & {
                            width: 160px;
                            text-align: center;
                            span {
                                display: inline-block;
                                white-space: pre-line;
                            }
                        }
                    }
                }

                &.first_scoll {
                    justify-content: normal;
                }

                &:last-child {
                    height: 80vh;
                    .desc {
                        margin-top: 38px;
                    }
                }
            }
        }

        .app_series_sticky {
            width: 100%;
            padding-left: 580px;
            padding-top: 228px;
            height: 395vh;
            position: relative;

            .app_series_sticky_item {
                position: sticky;
                top: 228px;
                right: 0;
                width: 100%;
                height: 100vh;

                .sticky_item_position {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 759px;
                    .sticky_in {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .phone {
                        position: absolute;
                        top: 0;
                        left: -5px;
                        //left: 100px;
                        width: 515px;
                        height: 759px;
                        z-index: 2;
                        html[site='jp'] & {
                            left: 55px;
                        }
                        html[site='us'] & {
                            left: 0;
                        }
                    }

                    .dynamo {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        right: 0;
                        width: 440px;
                        //display: none;
                        height: 759px;
                        transform: translateX(150px);
                        transition: transform 400ms ease-in-out, opacity 400ms ease-out;
                        .dynamo_in {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &.sticky02 {
                        .dynamo {
                            width: 350px;
                        }
                    }
                    &.sticky03 {
                        height: 80vh;
                        .dynamo {
                            width: 532px;
                        }
                    }
                }

                // .phone {
                //     position: relative;
                // }

                // .dynamo {
                //     position: relative;
                // }
            }
            .phone {
                opacity: 0;
            }
            .dynamo {
                opacity: 0;
            }
            &.isEnterA1 {
                .sticky01 {
                    z-index: 3;
                    .phone {
                        opacity: 1;
                        z-index: 2;
                    }
                    .dynamo {
                        opacity: 1;
                        z-index: 1;
                        transform: translateX(0px) !important;
                        transition: transform 300ms ease-in-out, opacity 300ms ease-out;
                    }
                }
            }
            &.isEnterA2 {
                .sticky01 {
                    .dynamo {
                        opacity: 0;
                        transform: translateX(-150px) !important;
                        transition: transform 300ms ease-in-out, opacity 300ms ease-out;
                    }
                }
                .sticky02 {
                    z-index: 3;
                    .phone {
                        opacity: 1;
                    }
                    .dynamo {
                        opacity: 1;
                        transform: translateX(0px) !important;
                        transition: transform 300ms ease-in-out, opacity 300ms ease-out;
                    }
                }
            }
            &.isEnterA3 {
                .sticky02 {
                    .dynamo {
                        opacity: 0;
                        transform: translateX(-150px) !important;
                        transition: transform 300ms ease-in-out, opacity 300ms ease-out;
                    }
                }
                .sticky03 {
                    z-index: 3;
                    .phone {
                        opacity: 1;
                    }
                    .dynamo {
                        opacity: 1;
                        transform: translateX(0px) !important;
                        transition: transform 300ms ease-in-out, opacity 300ms ease-out;
                    }
                }
            }
        }
    }
}
