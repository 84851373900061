
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.EFVideoModal {
    :global {
        .ef-video-modal {
            position: fixed;
            display: flex;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #111;
            justify-content: center;
            align-items: center;
            z-index: 999999;

            .youtube-player {
                display: none;
            }

            .close-btn {
                position: absolute;
                top: calc(50vh - 300px);
                right: calc(50vw - 500px);
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    @include maxWidth('lg') {
        :global {
            .ef-video-modal {
                align-items: none;

                .close-btn {
                    top: 0.7rem;
                    right: 0.15rem;
                }
            }

            .ef-video-modal > iframe {
                width: 100%;
                margin-top: 0.9rem;
                background-color: #111;
            }

            .video-ali {
                width: 3.75rem !important;
                height: 2.1rem !important;
            }
        }
    }
}
