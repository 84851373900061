
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.quickGuide {
    :global {
        .quick-guide {
            width: 100%;

            .select_box {
                display: flex;
                height: 0.5rem;
                border-bottom: 1px solid #ebe9e9;

                .ant-select {
                    flex: 1;
                    height: 100%;

                    .ant-select-selector {
                        height: 100%;
                        border: none;
                        box-shadow: none;
                        border-right: 1px solid #ebe9e9;
                        background-color: transparent;

                        input {
                            height: 100%;
                        }

                        .ant-select-selection-item {
                            line-height: 0.5rem;
                            color: #03060b;
                        }
                    }
                    .ant-select-selection--single {
                        box-shadow: none;
                    }
                    .ant-select-selection-item {
                        white-space: pre-line;
                    }
                }

                .icon {
                    transform: rotate(90deg);
                }
            }

            .content-box {
                .child-box {
                    margin-top: 0.3rem;
                    padding-bottom: 0.6rem;
                }
            }
        }
    }
}
