
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.iotComp {
    :global {
        .iot-comp {
            width: 3.25rem;
            margin: auto;
            .tab-box {
                height: 0.3rem;
                border-bottom: 1px solid #ebe9e9;
                display: flex;
                justify-content: space-between;
                html[site='jp'] & {
                    height: 0.4rem;
                }
                html[site='de'] & {
                    height: 0.38rem;
                }
                html[site='fr'] & {
                    height: 0.38rem;
                }
                .item-box {
                    position: relative;
                    top: 0.01rem;
                    display: flex;
                    align-items: center;
                    .tab-item {
                        cursor: pointer;
                        font-size: 0.14rem;
                        font-family: var(--font-family-SemiBold);
                        color: #6d7278;
                        line-height: 0.16rem;
                        margin-left: 0.04rem;
                        html[site='jp'] & {
                            font-weight: 500;
                            width: 0.6rem;
                            white-space: pre-line;
                        }
                        html[site='cn'] & {
                            font-weight: 500;
                        }
                    }
                    svg {
                        margin-top: -0.02rem;
                        flex-shrink: 0;
                    }
                    &.active {
                        border-bottom: 0.01rem solid #03060b;
                        color: #03060b;
                        .tab-item {
                            color: #03060b;
                        }
                    }
                }
            }
            .box {
                .box-title {
                    margin-top: 0.3rem;
                    font-size: 0.2rem;
                    color: #03060b;
                    line-height: 0.28rem;
                    font-family: var(--font-family-SemiBold);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 500;
                    }
                }
                .desc {
                    font-size: 0.14rem;
                    font-family: var(--font-family-Medium);
                    color: #35404e;
                    line-height: 0.22rem;
                    margin-top: 0.3rem;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
                .video-box {
                    margin-top: 0.2rem;
                    width: 3.25rem;
                    height: 3.25rem;
                    position: relative;
                    border-radius: 0.14rem;
                    .video {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 0.14rem;
                    }
                }
            }
        }
    }
}
