
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.Tips {
    :global {
        width: 100%;
        padding: 0 0 100px;
        background-color: #f8f8f8;

        .text {
            width: 1120px;
            margin: 0 auto;
            font-family: var(--ff-woff2-M);
            font-size: 17px;
            line-height: 1.4;
            letter-spacing: 0;
            color: #757575;
        }

        @include devices(pad) {
            background-color: unset;
            .text {
                width: 100%;
                padding: 0 48px;
                font-size: 0.12rem;
            }
        }
        @include devices(h5) {
            .text {
                padding: 0 24px;
            }
        }
    }
}
