
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.Part3 {
    :global {
        position: relative;
        width: 100%;
        height: calc(100vh + 400px);
        background-color: #fff;
        .ef-picture {
            height: 100vh;
            position: sticky;
            top: 0;
            left: 0;
        }
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
        }
        .text-wrapper {
            position: absolute;
            width: 700px;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            .subtitle {
                font-family: var(--ff-woff2-M);
                font-size: 20px;
                line-height: 1.3;
            }
            .title {
                margin-top: 12px;
                font-family: var(--ff-woff2-EB);
                font-size: 44px;
                line-height: 1.2;
            }
            html[site='de'] & {
                width: 800px;
            }
            html[site='fr'] & {
                width: 800px;
            }
        }
        @include devices(pc) {
        }
        @include devices(pad) {
            .text-wrapper {
                width: 500px;
                .subtitle {
                    font-size: 18px;
                }
                .title {
                    font-size: 28px;
                }
                html[site='de'] & {
                    width: 100%;
                    padding: 0 128px;
                }
                html[site='fr'] & {
                    width: 100%;
                    padding: 0 128px;
                }
            }
        }
        @include devices(h5) {
            .text-wrapper {
                width: 100% !important;
                padding: 0 24px !important;
                .subtitle {
                    font-size: 18px;
                }
                .title {
                    font-size: 28px;
                }
            }
        }
    }
}
