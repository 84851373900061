
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.tutorialVideo {
    :global {
        .tutorial-video {
            width: 1120px;
            margin: 80px auto 0;
            .videoUL {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .videoItem {
                    margin: 0 53px 60px 0;
                    flex-shrink: 0;
                    width: 240px;
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    &:hover {
                        .video_title {
                            color: #2673ff;
                        }
                    }
                    .videos {
                        display: block;
                        position: relative;
                        width: 240px;
                        height: 160px;
                        overflow: hidden;
                        .mask {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 294px;
                            height: 196px;
                            transform: translate(-50%, -50%);
                            background-color: transparent;
                            cursor: pointer;
                            z-index: 10;
                            .play {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 44px;
                                height: 44px;
                            }
                        }
                        // video{width: 100%;height: 100%}
                        .videoModules {
                            padding: 0;
                            .video-wrap {
                                max-width: inherit;
                                height: 164px !important;
                                .video-react-big-play-button {
                                    height: 44px;
                                    width: 44px;
                                    line-height: 44px;
                                }
                                .video-react-big-play-button.big-play-button-hide {
                                    display: block;
                                }
                                .video-react-big-play-button.video-react-big-play-button-center {
                                    margin-left: -22px;
                                    margin-top: -22px;
                                }
                            }
                        }
                    }
                    .video_title {
                        margin-top: 12px;
                        line-height: 1.47;
                        font-size: 16px;
                    }
                }
            }
            .video-spin {
                position: absolute;
                top: 50px;
            }
        }
    }
    @include maxWidth('lg') {
        :global {
            .tutorial-video {
                width: 100%;
                margin: 0;

                .play {
                    width: 0.44rem;
                    height: 0.44rem;
                }
                .videoUL {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    .videoItem {
                        width: 2.58rem;
                        margin: 0 auto 0.6rem;
                        .videos {
                            border-radius: 0.04rem;
                            width: 100%;
                            height: 1.69rem;
                            .mask {
                                width: 3.61rem;
                                height: 2.366rem;
                            }
                        }
                        .video_title {
                            width: 2.1rem;
                            line-height: 0.25rem;
                            font-size: 0.17rem;
                            margin: 0.1rem auto;
                            text-align: center;
                            font-family: var(--font-family-Regular);
                            font-weight: 400;
                        }

                        &:nth-child(4n) {
                            margin-right: auto;
                        }
                    }
                }
            }
            .page-content {
                padding-left: 0;
                position: relative;
                bottom: unset;
                margin-top: -0.2rem;
            }
            .video-spin {
                top: 0;
                left: 0.4rem;
            }
            .video-big {
                width: 100% !important;
                .closeIcon {
                    right: 0;
                    opacity: 1;
                }
            }
        }
    }
}

.tutorialVideoTitle {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    color: var(--navbg);
    :global {
        .supportIcon {
            width: 19px;
            height: 23px;
        }
        .supportIcon > div {
            width: 100%;
            height: 100%;
            position: relative !important;
        }
        span {
            padding-left: 12px;
            font-weight: 500;
            font-size: 32px;
        }
    }
    @include maxWidth('lg') {
        padding: 0.4rem 0.25rem 0.4rem;
        :global {
            .supportIcon {
                width: 0.19rem;
                height: 0.23rem;
            }
            span {
                padding-left: 0.08rem;
                font-size: 0.24rem;
                line-height: 0.33rem;
                font-family: var(--font-family-Bold);
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 600;
                }
            }
        }
    }
}
