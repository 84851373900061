
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.directConnection {
    :global {
        .table-box {
            width: 3.25rem;
            margin: auto;
            .table-title {
                font-size: 0.2rem;
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                color: #03060b;
                line-height: 0.28rem;
                font-family: var(--font-family-SemiBold);
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 500;
                }
            }
            .circle {
                width: 0.12rem;
                height: 0.12rem;
                background: #2ad16f;
                display: inline-block;
                border: 0.01rem solid rgba(0, 0, 0, 0.05);
                border-radius: 50%;
            }
            .ant-table {
                background-color: transparent;
                table {
                    border-top: 1px solid #ededed !important;
                }
                .ant-table-tbody{
                    .ant-table-measure-row > td:first-child{
                        html[site=cn] & {
                            width: 35% !important;
                        }
                    }
                    .ant-table-row .ant-table-cell:first-child{
                        html[site=cn] & {
                            width: 35% !important;
                            white-space: pre-line;
                        }
                    }
                }
                .ant-table-cell {
                    border-right: 1px solid #ededed !important;
                }
            }
            .ant-table.ant-table-bordered > .ant-table-container {
                border-left: 1px solid #ededed !important;
            }
            .ant-table-thead {
                .ant-table-cell {
                    background-color: #f0f0f0;
                    border-right: 0.01rem solid #ebe9e9 !important;
                    font-size: 0.12rem;
                    color: #35404e;
                    line-height: 0.16rem;
                    font-family: var(--font-family-Medium);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
            .ant-table-tbody {
                .ant-table-cell {
                    color: #35404e;
                    font-size: 0.12rem;
                    line-height: 0.16rem;
                    font-family: var(--font-family-Regular);
                    background-color: #f8f8f8;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td,
            .ant-table tfoot > tr > th,
            .ant-table tfoot > tr > td {
                padding: 12px !important;
            }
            .ant-table-tbody > tr > td {
                border-bottom: 1px solid #ededed !important;
            }
        }
    }
}
