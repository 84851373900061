
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.secondSeriesInApp {
    :global {
        background-color: #fff;
        .second-series {
            margin: auto;
            padding-bottom: 312px;
            width: 1200px;
            overflow: hidden;
            top: 0;
            .title {
                width: 840px;
                margin: auto;
                //padding-top: 110px;
                text-align: center;
                font-family: var(--font-family-ExtraBold);
                color: #03060b;
                font-size: 64px;
                line-height: 72px;
                opacity: 0;
                html[site='jp'] & {
                    font-weight: 500;
                    font-size: 54px;
                    line-height: 64px;
                    font-family: var(--font-family-SemiBold);
                    white-space: pre-line;
                }
                html[site='cn'] & {
                    font-weight: 700;
                }
            }
            .ani-box {
                margin-top: 300px;
                .sm-img {
                    width: 1272px;
                    height: 1060px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    transform-origin: top center;
                }
            }
            .move-box {
                position: relative;
                .phone-box {
                    width: 782px;
                    height: 841px;
                    position: absolute;
                    &.bottom-phone{
                        .phone-text{
                            html[site='cis'] & {
                                top: 730px;
                            }
                        }
                    }
                    .phone-text {
                        width: 336px;
                        font-family: var(--font-family-ExtraBold);
                        color: #03060b;
                        font-size: 28px;
                        line-height: 32px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 730.76px;
                        html[site=br] & {
                            top: 700px;
                        }
                        html[site='cn'] & {
                            font-weight: 700;
                        }
                        html[site='cis'] & {
                            top: 730px;
                        }
                        html[site='jp'] & {
                            font-weight: 500;
                            white-space: pre-line;
                            font-family: var(--font-family-SemiBold);
                        }
                    }
                    .phone-desc {
                        position: absolute;
                        font-family: var(--font-family-Bold);
                        width: 336px;
                        color: #666666;
                        line-height: 27px;
                        font-size: 19px;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 772.76px;
                        html[site='cn'] & {
                            font-weight: 600;
                        }
                        html[site='jp'] & {
                            font-size: 18px;
                            line-height: 25px;
                            font-family: var(--font-family-Medium);
                            font-weight: 400;
                        }
                        html[site='de'] & {
                            top: 832.76px;
                        }
                        html[site='fr'] & {
                            top: 802.76px;
                        }
                        html[site='cis'] & {
                            top: 800px;
                        }
                    }
                }
                .top-box {
                    .left-phone {
                        opacity: 0;
                        top: -140px;
                        left: -190px;
                    }
                    .right-phone {
                        opacity: 0;
                        top: 110px;
                        right: -190px;
                        .phone-desc {
                            html[site='de'] & {
                                top: 802.76px;
                            }
                            html[site='fr'] & {
                                top: 832.76px;
                            }
                        }
                    }
                }
                .bottom-box {
                    .bottom-phone {
                        position: relative;
                        opacity: 0;
                        top: 185px;
                        margin: -20px auto 0;
                        .phone-text {
                            width: 380px;
                        }
                        .phone-desc {
                            width: 380px;
                            html[site='jp'] & {
                                top: 805.76px;
                            }
                            html[site='cis'] & {
                                top: 770px;
                            }
                        }
                    }
                }
            }
        }
    }
}
