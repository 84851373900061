
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.directConnection {
    :global {
        .table-box {
            margin-left: 56px;
            padding-bottom: 60px;
            .circle {
                width: 12px;
                height: 12px;
                background: #2ad16f;
                display: inline-block;
                border: 1px solid rgba(0, 0, 0, 0.05);
                border-radius: 50%;
            }
            .ant-table {
                background-color: transparent;
                table {
                    border-top: 1px solid #ededed !important;
                }
                .ant-table-cell {
                    border-right: 1px solid #ededed !important;
                }
            }
            .ant-table.ant-table-bordered > .ant-table-container {
                border-left: 1px solid #ededed !important;
            }
            .ant-table-thead {
                .ant-table-cell {
                    background-color: #f0f0f0;
                    border-right: 1px solid #ebe9e9 !important;
                    font-size: 14px;
                    color: #35404e;
                    line-height: 18px;
                    font-family: var(--font-family-Medium);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 500;
                    }
                }
            }
            .ant-table-tbody {
                .ant-table-cell {
                    color: #35404e;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-family-Regular);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
            .ant-table-tbody > tr > td {
                border-bottom: 1px solid #ededed !important;
            }
        }
    }
}
